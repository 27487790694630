export const calculationTabs = [
    'basic-input',
    'configuration',
    'ventilation-components',
    'cost-calculation',
] as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type calculationTabs = typeof calculationTabs[number];

export function isCalculationTab(v: unknown): v is calculationTabs {
    return typeof v === 'string' && calculationTabs.includes(v as any);
}


interface IActionDef<T, P> {
    meta: T;
    payload?: P;
}

export const createAction = <T, P>(prefix: string, def: IActionDef<T, P>) => {
    return <const>{
        ...def,
        type: `${prefix}/${def.meta}`,
    };
};
