import { v1 as uuidv1 } from "uuid";

import { IProduct, IProductData } from "../product/types";

import {
    INonStandardComponent,
    ISelectedProduct,
} from './CalculationModels';
import { SelectedProductsBuilder } from "./SelectedProductsBuilder";
import { CalculationActionTypes, KnownCalculationActions } from './CalculationActions';
import { getTotalLiftArea } from "./CalculationService/LiftArea";
import {
    getApplicableAIOBasicSets,
    getSelectedAIOBasicSet,
} from "./CalculationService/AIOBasicSet";
import {
    getAvailableVentilationComponents,
    ProductSelector
} from "./CalculationService/VentilationComponents";
import {
    BasicInputsFormModel,
    ConfigurationInputsFormModel,
    VentilationComponentsFormModel,
    CostCalculationFormModel
} from "./models/FormModels";


export interface ICalculationState {
    /** The index of the currently open tab (0-3) */
    activeTabIndex: number;

    /** The products selected by business logic rules */
    selectedProducts: ISelectedProduct[];

    /** Manually added non-standard components */
    nonStandardComponents: INonStandardComponent[];

    /// Ventilation Components

    /** The total lift area relevant for component selection */
    totalLiftArea: number;

    /**Suitable ventilation components */
    filteredVentilationComponents: ProductSelector[];

    /**Suitable weather protection components */
    filteredWeatherProtectionComponents: ProductSelector[];

    /// AIO Basic Sets

    /** The Set that has been selected by the user */
    selectedAIOBasicSet: ISelectedProduct;

    /** The Sets that are suitable for the customer given current form values */
    aioBasicSets: IProduct[];

    /** The user's choice to ignore proposed Sets */
    ignoreAIOBasicSets: boolean;

    /** AIO Basic Sets modal open state flags */
    isAIOBasicSetModalOpen: boolean;

    /// Interface to hook-based calculation API

    /**
     * Signifies that this state has been sufficiently initialized to enable fetching a cost calculation.
     * 
     * This was introduced to stop the new `useCalculateCosts()` hook in `CostCalculationForm`
     * from calling the HTTP API before this state has been rehydrated via a `PROCESS_FORM_VALUES` action.
     * This was broken when cold-loading `/calculation/:projectId/cost-calculation`.
     */
    isReadyForCalculation: boolean;
};

export enum Direction {
    Next = 'next',
    Previous = 'previous'
}

export enum Tabs {
    BasicInput = 0,
    Configuration = 1,
    VentilationComponents = 2,
    CostCalculation = 3
}


const initialState: ICalculationState = {
    // UI State
    activeTabIndex: 0,
    ignoreAIOBasicSets: false,
    isAIOBasicSetModalOpen: false,
    isReadyForCalculation: false,

    // Calculation State
    aioBasicSets: [],
    selectedProducts: [],
    selectedAIOBasicSet: null,
    totalLiftArea: 0,
    filteredVentilationComponents: [],
    filteredWeatherProtectionComponents: [],
    nonStandardComponents: []
};

function processFormValuesToStateUpdate(
    basicValues: BasicInputsFormModel,
    configValues: ConfigurationInputsFormModel,
    ventilationComponentsValues: VentilationComponentsFormModel,
    costCalculationValues: CostCalculationFormModel,
    productData: IProductData,
): Partial<ICalculationState> {
    const totalLiftArea = getTotalLiftArea(basicValues);
    if (!totalLiftArea) {
        return {
            selectedProducts: [],
            selectedAIOBasicSet: null,
            totalLiftArea,
            filteredVentilationComponents: [],
            filteredWeatherProtectionComponents: [],
            aioBasicSets: [],
            isReadyForCalculation: false,
        };
    }

    const selectedAIOBasicSet = getSelectedAIOBasicSet(basicValues, productData.products);

    let selectedProducts: ISelectedProduct[] = [];
    let isReadyForCalculation = false;

    if (configValues && ventilationComponentsValues) {
        const flow = selectedAIOBasicSet ? 'complete-set' : 'default';
        try {
            selectedProducts = SelectedProductsBuilder.selectProductsFromFlow(flow,
                totalLiftArea,
                basicValues,
                configValues,
                ventilationComponentsValues,
                costCalculationValues,
                productData
            );
            isReadyForCalculation = true;
        } catch (e) {
            console.error(`SelectedProductsBuilder.selectProductsFromFlow('${flow}') failed`, e);
        }
    }

    const minArea = totalLiftArea;
    const availableComponents = getAvailableVentilationComponents(totalLiftArea, minArea, basicValues, configValues, productData);

    return {
        totalLiftArea,
        selectedAIOBasicSet,
        selectedProducts,
        aioBasicSets: getApplicableAIOBasicSets(basicValues, productData.products),
        filteredVentilationComponents: availableComponents.ventComponents,
        filteredWeatherProtectionComponents: availableComponents.weatherProtectionComponents,
        isReadyForCalculation,
    };
}

export const reducer = (state = initialState, action: KnownCalculationActions): ICalculationState => {
    const { activeTabIndex } = state;

    switch (action.type) {
        case CalculationActionTypes.PROCESS_FORM_VALUES: {

            const basicValues = action?.payload?.basicInputsForm?.values;
            const configValues = action?.payload?.configurationInputsForm?.values;
            const ventilationComponentsValues = action?.payload?.ventilationComponentsForm?.values;
            const costCalculationValues = action?.payload?.costCalculationForm?.values;
            const productData = action?.extraProductData;

            const stateOverrides = processFormValuesToStateUpdate(
                basicValues, configValues,
                ventilationComponentsValues, costCalculationValues,
                productData
            );

            return {
                ...state,
                ...stateOverrides,
            };

        }

        case CalculationActionTypes.SWITCH_TAB:

            if (action.payload === Direction.Previous && activeTabIndex === Tabs.CostCalculation && state.selectedAIOBasicSet) {
                // when a set is selected and navigating backwards from cost calculation tab,
                // then go to basic inputs form
                return {
                    ...state,
                    activeTabIndex: Tabs.BasicInput,
                    isAIOBasicSetModalOpen: true,
                };
            }

            if (action.payload === Direction.Next && activeTabIndex === Tabs.BasicInput) {
                // when on basic inputs form and clicking on next button, ignore sets
                return {
                    ...state,
                    activeTabIndex: activeTabIndex + 1,
                    ignoreAIOBasicSets: true
                };
            }

            if (action.payload === Direction.Next && activeTabIndex < Tabs.CostCalculation) {
                return {...state, activeTabIndex: activeTabIndex + 1};
            }

            if (action.payload === Direction.Previous && activeTabIndex > Tabs.BasicInput) {
                return {...state, activeTabIndex: activeTabIndex - 1};
            }

            break;

        case CalculationActionTypes.SWITCH_TAB_INDEX:
            return {...state, activeTabIndex: action.payload};


        case CalculationActionTypes.IGNORE_AIO_BASIC_SETS:
            const ignoreAIOBasicSets = !!action.payload;
            return {
                ...state,
                ignoreAIOBasicSets,
                selectedAIOBasicSet: ignoreAIOBasicSets ? null : state.selectedAIOBasicSet,
            };

        case CalculationActionTypes.SET_AIO_BASIC_SET_MODAL_VISIBILITY:
            return {
                ...state,
                isAIOBasicSetModalOpen: action.payload,
            };

        case CalculationActionTypes.SET_NON_STANDARD_COMPONENT_LIST:
            return {
                ...state,
                nonStandardComponents: action.payload,
            };

        case CalculationActionTypes.ADD_NON_STANDARD_COMPONENT: 
            return {
                ...state,
                nonStandardComponents: [
                    ...state.nonStandardComponents,
                    {
                        productId: uuidv1(),
                        description: action.payload.description,
                        price: action.payload.price,
                        quantity: action.payload.quantity,
                    },
                ],
            };

        case CalculationActionTypes.REMOVE_NON_STANDARD_COMPONENT:
            return {
                ...state,
                nonStandardComponents: state.nonStandardComponents.filter(c => c.productId !== action.payload),
            };
    }

    return state;
};
