import * as React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {Field, getFormValues, change as reduxFormChange} from "redux-form";
import {Button, Form, Label, Modal} from 'semantic-ui-react';

import {ReduxFormKeys} from '../../../form/ReduxFormKeys';
import {IApplicationState} from "../../../../shared/ApplicationState";
import {TabSection} from '../../../../shared/components/TabSection';
import {renderQuantity, renderRadio} from "../../../../shared/components/FormControls";
import { nl2br } from '../../../../localization/LocalizationService';
import { useLocalization, useProductLanguage } from '../../../../localization/hook';

import { SET_PRODUCT_KEY } from '../../../product/lookup';
import { MACHINE_ROOM, SMOKE_DETECTION } from '../../../project/lookup';
import {calculationActionCreator} from "../../CalculationActions";
import { Direction, Tabs } from '../../CalculationReducer';
import {getAIOBasicSetShaftHeightLimits, getAIOBasicSetRequiresVentComponentSelection, getAIOBasicSetVent} from "../../CalculationService/AIOBasicSet";
import {BASIC_INPUTS_FORM_VALUES_SELECTOR} from '../02-ConfigurationInputs/ConfigurationInputsForm';


type OwnProps = {
    showTriggerButton?: boolean,
    triggerButtonLabel?: string,
};

const defaultProps: Partial<OwnProps> = {
    showTriggerButton: false,
    triggerButtonLabel: null,
};

const AIOBasicSetsModal: React.FC<Props> = (inputProps) => {
    const props: Props = {
        ...defaultProps,
        ...inputProps,
    };

    const [previousSelection, setPreviousSelection] = React.useState<string>(props.selectedAIOBasicSet?.id ?? null);

    /**
     * Called when the modal's trigger button is clicked.
     * The user wishes to see the modal and not (yet) ignore the sets.
    */
     const onTriggerClick = () => {
        props.ignoreAIOBasicSets(false);
        props.onSetOpen(true);
    };

    /**
     * Called when the confirmation button is clicked.
     * The user selected a set and wishes to fast-forward.
     */
    const onConfirmSelectClick = () => {
        props.onSetOpen(false);
        props.requestSwitchTabIndex(requiresVentComponentSelection
            ? Tabs.VentilationComponents
            : Tabs.CostCalculation
        );
    };

    /**
     * Called when the ignore button is clicked.
     * The user wishes to ignore sets and go to the next tab.
     */
    const onConfirmIgnoreClick = () => {
        props.onSetOpen(false);
        props.ignoreAIOBasicSets(true);
        props.requestSwitchTab(Direction.Next);
    };

    /**
     * Called when the modal's close button is clicked.
     * The user wishes to close the modal and return to where they came from without altering their selection.
     */
    const onCloseClick = () => {
        props.onSetOpen(false);
        // Cancel-like behavior: restore previous selection
        props.onAIOBasicSetSelect(previousSelection);
    }

    /** Called when the modal is opening. */
    const onOpening = () => {
        // Store the previous user selection in state
        setPreviousSelection(props.basicInputFormValues.aioBasicSet);
    };

    /** Called when the modal is closing. */
    const onClosing = () => {};

    const {
        aioBasicSets,
        selectedAIOBasicSet,
        totalLiftArea,
        showTriggerButton,
        triggerButtonLabel,
        open,
    } = props;
    const { projectType, machineRoom, weathershelter, installationPosition } = props.basicInputFormValues;

    const { localize } = useLocalization();
    const productLanguage = useProductLanguage();

    const requiresVentComponentSelection = getAIOBasicSetRequiresVentComponentSelection({ weathershelter, installationPosition });
    const skipDest = localize(requiresVentComponentSelection ? 'sets.modal.dest.3' : 'sets.modal.dest.4');
    const skipSteps = localize(requiresVentComponentSelection ? 'sets.modal.skip.2' : 'sets.modal.skip.2+3');

    return (
        <>
            <Modal className="layout-v1"
                trigger={showTriggerButton ?
                    <Button
                        onClick={onTriggerClick}
                        className={aioBasicSets.length > 0 ? 'green' : ''}
                        disabled={aioBasicSets.length === 0}>
                            {triggerButtonLabel ?? localize('sets.modal.trigger')}
                    </Button> : null
                }
                open={open}
                onOpen={onOpening}
                onClose={onClosing}
            >
                <Modal.Header>
                    <div className="title">{localize('sets.modal.title')}</div>
                    <div className="close-button" onClick={onCloseClick}><i aria-hidden="true" className="close icon"></i></div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>{nl2br(localize('sets.modal.text', { skipDest, skipSteps }))}</p>
                        <p>{nl2br(localize('sets.modal.contents'))}</p>
                        <Form.Group inline>
                            {aioBasicSets.map((set, index) => {

                                const array = set.productKey.split('-');

                                let smokeDetection: SMOKE_DETECTION = SMOKE_DETECTION.NONE;

                                if (set.productKey.match('-PD-'))
                                    smokeDetection = SMOKE_DETECTION.POINTDETECTOR;
                                else if (set.productKey.match('-SD-L-F1-'))
                                    smokeDetection = SMOKE_DETECTION.SDLF1;

                                let shaftHeightLimit: string = array[array.length - 2] + ' m';

                                {
                                    const limits = getAIOBasicSetShaftHeightLimits(set.productKey as unknown as SET_PRODUCT_KEY, machineRoom);
                                    if (limits && limits[1] < Infinity) {
                                        shaftHeightLimit = (limits[1] / 1_000).toLocaleString() + ' m';
                                        if (machineRoom !== MACHINE_ROOM.NO) {
                                            shaftHeightLimit += ' ' + localize('sets.modal.andMachineRoom');
                                        }
                                    }
                                }

                                const vent = getAIOBasicSetVent(projectType, totalLiftArea);

                                let isRecommended = index === 0

                                const selected = selectedAIOBasicSet?.id === set.id

                                return (
                                    <TabSection key={set.id}>
                                        <Field component={renderRadio} label={set.description[productLanguage]} name="aioBasicSet" radioValue={set.id}/>

                                        <div className="checkmark-item first">
                                            <span className="bluekit-icon icon-checkmark-sharp"></span>
                                            <label>{localize('sets.modal.smokeDetectionWith', { value: localize('SMOKE_DETECTION.' + smokeDetection) })}</label>
                                        </div>
                                        <div className="checkmark-item">
                                            <span className="bluekit-icon icon-checkmark-sharp"></span>
                                            <label>{localize('sets.modal.shaftHeightUpTo', { value: shaftHeightLimit })}</label>
                                        </div>

                                        {vent && <div className="checkmark-item">
                                            <span className="bluekit-icon icon-checkmark-sharp"></span>
                                            <label>{vent.name}</label>
                                        </div>}
                                        {selected &&
                                            <div className="number-interface-adapters">
                                                <div className="number-interface-adapters__description">{localize('field.noOfInterfaceAdapters')}</div>
                                                <Field min={0} max={3} name='noOfInterfaceAdapters' component={renderQuantity}/>
                                            </div>
                                        }
                                        {isRecommended &&
                                            <Label className="recRibbon" ribbon="right">
                                                {localize('info.recommendedForYou')}
                                            </Label>
                                        }

                                    </TabSection>
                                )
                            })}
                        </Form.Group>
                        <div className="btn-group centered">
                            <Button disabled={!selectedAIOBasicSet} primary onClick={onConfirmSelectClick}>
                                {localize('sets.modal.button.select', { skipDest })}
                            </Button>
                            <Button secondary onClick={onConfirmIgnoreClick}>
                                {localize('sets.modal.button.ignore')}
                            </Button>
                        </div>

                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
        // const showAIOSetsModalButton = calculationState.activeTabIndex === 0 && calculationState.aioBasicSets?.length !== 0;


const mapStateToProps = createStructuredSelector({
    basicInputFormValues: getFormValues(ReduxFormKeys.basicInputsForm) as BASIC_INPUTS_FORM_VALUES_SELECTOR,

    totalLiftArea: (state: IApplicationState) => state.calculationState.totalLiftArea,
    aioBasicSets: (state: IApplicationState) => state.calculationState.aioBasicSets,
    selectedAIOBasicSet: (state: IApplicationState) => state.calculationState.selectedAIOBasicSet,

    /** Whether the modal should be open */
    open: (state: IApplicationState) => state.calculationState.isAIOBasicSetModalOpen,
});

const mapDispatchToProps = {
    /** Called to go to the next tab (when sets ignored) */
    ignoreAIOBasicSets: calculationActionCreator.ignoreAIOBasicSets,

    /** Called to ignore sets (when sets ignored) */
    requestSwitchTab: calculationActionCreator.requestSwitchTab,

    /** Called to skip to tabs further ahead (when set selected) */
    requestSwitchTabIndex: calculationActionCreator.requestSwitchTabIndex,

    /** Called when the modal wants to open/close */
    onSetOpen: calculationActionCreator.setAIOBasicSetModalVisibility,

    /** Called when the modal wants to change the set selection */
    onAIOBasicSetSelect: (selection: string | null) => reduxFormChange(ReduxFormKeys.basicInputsForm, "aioBasicSet", selection),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AIOBasicSetsModal)

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type Props = OwnProps & StateProps & DispatchProps;
