import { COUNTRY, LIFT_AREA_PERCENTAGE, PROJECT_TYPE } from "../../project/lookup";
import { BasicInputsFormModel } from "../models/FormModels";


/** German LBOs specify a minimum smoke ventilation area of 0.1m^2 == 100_000 mm^2 */
const MINIMUM_VENTILATION_AREA_DE_LBO = 100_000;
const VENTILATION_AREA_PERCENTAGE_DE_LBO = {
    [LIFT_AREA_PERCENTAGE._2_5_PERCENT]: 2.5,
    [LIFT_AREA_PERCENTAGE._1_0_PERCENT]: 1.0,
};

/**
 * Returns minimum required ventilation area according to `liftAreaPercentage`.
 * @returns The minimum ventilation area in mm^2, or `null` if no minimum is applicable
 */
function calculateMinimumLiftArea(values: Pick<BasicInputsFormModel, 'liftAreaPercentage' | 'shaftWidth' | 'shaftDepth'>): number | null {

    const { liftAreaPercentage, shaftWidth, shaftDepth } = values;

    switch (liftAreaPercentage) {
        case LIFT_AREA_PERCENTAGE._2_5_PERCENT: /* fallthrough */
        case LIFT_AREA_PERCENTAGE._1_0_PERCENT: {
            if (isNaN(shaftDepth) || isNaN(shaftWidth)) {
                return null;
            }

            const liftFootprint = shaftDepth * shaftWidth;
            const ventilationArea = Math.ceil(liftFootprint * VENTILATION_AREA_PERCENTAGE_DE_LBO[liftAreaPercentage] / 100);

            return Math.max(MINIMUM_VENTILATION_AREA_DE_LBO, ventilationArea);
        }

        case LIFT_AREA_PERCENTAGE.MANUAL:
        default:
            return null;
    }
}

export function getAvailableLiftAreaPercentages({ country, projectType }: Partial<Pick<BasicInputsFormModel, 'country' | 'projectType'>>): LIFT_AREA_PERCENTAGE[] {
    switch (country) {
    case COUNTRY.DE: return [
        LIFT_AREA_PERCENTAGE._2_5_PERCENT,
        ...(projectType === PROJECT_TYPE.MODERNIZED ? [LIFT_AREA_PERCENTAGE.MANUAL] : []),
    ];
    case COUNTRY.AT: return [
        LIFT_AREA_PERCENTAGE._2_5_PERCENT,
        LIFT_AREA_PERCENTAGE._1_0_PERCENT,
        ...(projectType === PROJECT_TYPE.MODERNIZED ? [LIFT_AREA_PERCENTAGE.MANUAL] : []),
    ];
    default: return [
        LIFT_AREA_PERCENTAGE.MANUAL,
        // LIFT_AREA_PERCENTAGE._2_5_PERCENT,
        // LIFT_AREA_PERCENTAGE._1_0_PERCENT,
    ];
    }
}

// For 03-VentilationComponents
export function getTotalLiftArea(
    basicValues: (Pick<BasicInputsFormModel, 'country' | 'projectType' | 'calculatedVentilationArea' | 'liftAreaPercentage'> & Parameters<typeof calculateMinimumLiftArea>[0]) | undefined
) {
    if (!basicValues) {
        return null;
    }

    const {
        country,
        projectType,
        calculatedVentilationArea,
        liftAreaPercentage,
    } = basicValues;

    if (
        liftAreaPercentage === LIFT_AREA_PERCENTAGE.MANUAL &&
        getAvailableLiftAreaPercentages({ country, projectType }).includes(LIFT_AREA_PERCENTAGE.MANUAL)
    ) {
        // Ventilation area has been entered manually and this is permitted
        return calculatedVentilationArea;
    }

    return calculateMinimumLiftArea(basicValues);
}
