import * as React from 'react';
import { Container, Grid, Icon, Input, InputOnChangeData, Menu, Segment } from "semantic-ui-react";

import { RestrictLanguages, useLocalization } from '../../localization/hook';
import { RestrictedLanguageMessage } from '../../localization/RestrictedLanguageMessage';
import { RestrictedCurrencyMessage } from '../../localization/RestrictedCurrencyMessage';

import { isAdmin } from "../auth/PermissionService";
import { useUser } from '../auth/User.hook';

import { ProjectTable } from "./_ProjectTable";
import { GetProjectsView } from './ProjectApiV1Client';

import './ProjectExplorerPage.scss';


export const InnerProjectExplorerPage: React.FC = () => {
    const { localize } = useLocalization();
    const { user } = useUser();

    const [projectView, setProjectView] = React.useState(GetProjectsView.OWN);
    const onSetProjectViewClick = React.useCallback((view: GetProjectsView) => () => {
        setProjectView(view);
    }, []);

    // The query parameter passed to ProjectTable
    const [tableQuery, setTableQuery] = React.useState<string|null>('');

    // The query text from the search input
    const [tableQueryInputValue, setTableQueryInputValue] = React.useState('');
    const onTableQueryInputValueChange = React.useCallback((_event, data: InputOnChangeData) => {
        const q = data.value?.trim().length ? data.value : '';
        setTableQueryInputValue(q);
    }, [setTableQueryInputValue]);
    const onTableQueryInputValueClear = React.useCallback(() => {
        setTableQueryInputValue('');
    }, [setTableQueryInputValue]);

    // Update ProjectTable's query no more than once every 500ms
    useDebounce(500, setTableQuery, tableQueryInputValue);

    const userIsAdmin = isAdmin(user);

    return (
        <Container><Grid className='ProjectExplorerPage' columns={1} padded>
            <RestrictedLanguageMessage className='full-width' />
            <RestrictedCurrencyMessage className='full-width' />

            <Grid.Row><Grid.Column as={Segment}>
                <Menu pointing>
                    <Menu.Item icon='user'
                        active={projectView === GetProjectsView.OWN}
                        onClick={onSetProjectViewClick(GetProjectsView.OWN)}
                        content={localize('projectList.view.OWN')}/>
                    <Menu.Item icon='building'
                        active={projectView === GetProjectsView.OWN_COMPANY}
                        onClick={onSetProjectViewClick(GetProjectsView.OWN_COMPANY)}
                        content={localize('projectList.view.OWN_COMPANY')}/>
                    {userIsAdmin && (
                        <Menu.Item icon='globe'
                            active={projectView === GetProjectsView.ALL}
                            onClick={onSetProjectViewClick(GetProjectsView.ALL)}
                            content={localize('projectList.view.ALL')}/>
                    )}
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Input type='text' size='small' icon value={tableQueryInputValue} onChange={onTableQueryInputValueChange}
                                placeholder={localize('projectList.search.label')}>
                                <input/>
                                {!!tableQueryInputValue?.length && (
                                    <Icon name='x' link disabled={!tableQueryInputValue?.length} onClick={onTableQueryInputValueClear}/>
                                )}
                            </Input>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <ProjectTable view={projectView} query={tableQuery} compact='very' basic fixed/>
            </Grid.Column></Grid.Row>
        </Grid></Container>
    );
}

export const ProjectExplorerPage: React.FC = () => {
    return (
        <RestrictLanguages languages={['de', 'en', 'pl']}>
            <InnerProjectExplorerPage />
        </RestrictLanguages>
    );
};

/** Calls `fn` after `timeoutMs`; timer resets whenever any of `...args` change */
function useDebounce<T extends unknown[]>(timeoutMs: number, fn: (...args: T) => unknown, ...args: T) {
    React.useEffect(() => {
        const debounceTimer = setTimeout(() => fn(...args), timeoutMs);
        return () => clearTimeout(debounceTimer);
    }, [timeoutMs, fn, ...args]);
}
