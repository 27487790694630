import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import {
    Button,
    Checkbox,
    DropdownItemProps as SemanticDropdownItemProps,
    DropdownProps as SemanticDropdownProps,
    Form,
    Input,
    InputProps as SemanticInputProps,
    Popup,
    Select,
    SemanticShorthandContent,
    TextAreaProps as SemanticTextAreaProps,
    CheckboxProps as SemanticCheckboxProps,
} from 'semantic-ui-react';


/////////////////////////////
// Hidden Input
/////////////////////////////

export type HiddenFieldProps = WrappedFieldProps & {};

export const renderHidden: React.FC<HiddenFieldProps> = ({ input }) => (
    <div style={{ display: 'none' }}>
        <Form.Field>
            <Input
                name={input.name}
                value={input.value}
                type="hidden"
            />
        </Form.Field>
    </div>
);


/////////////////////////////
// Checkbox
/////////////////////////////

export type CheckboxFieldProps = WrappedFieldProps & {};

export const renderCheckbox = field => (
    <Form.Field style={field.style}>
        <Checkbox
            style={field.inputStyle}
            data-cy={field.input.name}
            checked={!!field.input.value}
            name={field.input.name}
            disabled={field.disabled}
            onChange={(_event: unknown, { checked }: SemanticCheckboxProps) => field.input.onChange(checked)}
            label={field.label}
        />
        {field.infoText && <Popup content={field.infoText} trigger={<Button className="info-button" icon="info"/>}/>}
    </Form.Field>
);


/////////////////////////////
// Radio
/////////////////////////////

export type RadioFieldProps = WrappedFieldProps & {
    radioValue: string;
    disabled: boolean;
    label: string;
};

export const renderRadio: React.FC<RadioFieldProps> = ({ input, radioValue, disabled, label }) => {
    const radioProps = {
        disabled,
        label,
        name: input.name,
        checked: input.value === radioValue,
        "data-cy": `${input.name}-${radioValue}`,
        onChange: () => {
            input.onChange(radioValue);
        },
    };

    return <Form.Radio {...radioProps}/>;
};


/////////////////////////////
// Select Dropdown
/////////////////////////////

export type SelectFieldProps = WrappedFieldProps & {
    options: SemanticDropdownItemProps[];
    defaultValue?: any;
    placeholder?: string;
    disabled?: boolean;
    infoText?: SemanticShorthandContent;
    label: string;
    search?: boolean;
};

const SelectField: React.FC<SelectFieldProps> = ({ input, meta, options, search, placeholder, disabled, defaultValue, label, infoText }) => {
    const selectProps = {
        name: input.name,
        options,
        search,
        placeholder,
        disabled,
        value: input.value || defaultValue,
        onBlur: (_event: unknown, { value }: SemanticDropdownProps) => input.onBlur(value),
        onChange: (_event: unknown, { value }: SemanticDropdownProps) => input.onChange(value),
    };
    return (
        <Form.Field>
            <label>
                {label}
                {infoText && <Popup content={infoText} trigger={<Button className="info-button" icon="info"/>}/>}
            </label>
            <Select {...selectProps}/>
            <div style={{position: 'relative'}}>
                {meta.touched && <>
                    {meta.error && (
                        <div className="ui basic red pointing prompt label transition visible error-message">
                            {meta.error}
                        </div>
                    )}
                    {!meta.error && meta.warning && (
                        <span>{meta.warning}</span>
                    )}
                </>}
            </div>
        </Form.Field>
    );
}
export const renderSelect = SelectField;

export const renderSearchableSelect: React.FC<SelectFieldProps> = props => {
    return <SelectField {...props} search/>
};


/////////////////////////////
// Text
/////////////////////////////

export type InputFieldProps = WrappedFieldProps & {
    label?: string;
    infoText?: SemanticShorthandContent;
    hideError?: boolean;
} & Pick<SemanticInputProps, 'size'|'fluid'|'type'|'placeholder'|'action'|'disabled'>;

export const renderInput: React.FC<InputFieldProps> = (field: InputFieldProps) => (
    <Form.Field>
        {field.label &&
        <label>
            {field.label}
            {field.infoText && <Popup content={field.infoText} trigger={<Button className="info-button" icon="info"/>}/>}
        </label>}
        <Input
            size={field.size}
            fluid={field.fluid}
            value={field.input.value}
            type={field.type}
            error={!!(field.meta.touched && (field.meta.error || field.meta.warning))}
            name={field.input.name}
            placeholder={field.placeholder}
            disabled={field.disabled}
            onChange={(e, {value}) => field.input.onChange(value)}
            action={field.action}
            onBlur={field.input.onBlur}
        />
        <div style={{position: 'relative'}}>
            {!field.hideError && field.meta.touched && ((field.meta.error && <div className="ui basic red pointing prompt label transition visible error-message">{field.meta.error}</div>) || (field.meta.warning &&
                <span>{field.meta.warning}</span>))}
        </div>
    </Form.Field>
);


/////////////////////////////
// Text Area
/////////////////////////////

export type TextAreaInputProps = WrappedFieldProps & Pick<SemanticTextAreaProps, 'label'|'rows'|'placeholder'|'style'>;

export const renderTextArea: React.FC<TextAreaInputProps> = ({ input, label, placeholder, rows, style }) => (
    <Form.TextArea
        {...input}
        label={label}
        placeholder={placeholder}
        value={input.value}
        rows={rows}
        style={style}
    />
);


/////////////////////////////
// Quantity `[-] 1 [+]`
/////////////////////////////

export type QuantityInputProps = WrappedFieldProps & {
    label?: string;
    labelPosition?: 'start' | 'end';
    infoText?: SemanticShorthandContent;
    disabled?: boolean; // @TODO
    min: number;
    max: number;
};

export const renderQuantity: React.FC<QuantityInputProps> = ({ label, labelPosition, infoText, input, disabled, min, max }) => {
    const valueFromAction = parseInt(input.value ? input.value : min);
    const minIsDisabled = !isNaN(min) && min === valueFromAction;
    const maxIsDisabled = !isNaN(max) && max === valueFromAction;

    const onMinusClick = () => input.onChange(valueFromAction - 1);
    const onPlusClick  = () => input.onChange(valueFromAction + 1);

    return (
        <div
            data-cy={input.name}
            className="numeric-component field"
        >
            {label && (!labelPosition || labelPosition === 'start') && (
                <label>
                    {label}
                    {infoText && (
                        <Popup content={infoText} openOnTriggerClick={false} trigger={<Button className="info-button" icon="info"/>}/>
                    )}
                </label>
            )}
            <Button
                type="button"
                data-cy="minus"
                className="numeric-button"
                icon="minus"
                disabled={disabled || minIsDisabled}
                onClick={onMinusClick}
            />
            <span>{valueFromAction}</span>
            <Button
                type="button"
                data-cy="plus"
                className="numeric-button"
                icon="plus"
                disabled={disabled || maxIsDisabled}
                onClick={onPlusClick}
            />
            {label && (labelPosition === 'end') && (
                <label className="position-end">
                    {label}
                    {infoText && (
                        <Popup content={infoText} trigger={<Button className="info-button" icon="info"/>}/>
                    )}
                </label>
            )}
        </div>
    );
};
