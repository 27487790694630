import * as React from 'react';
import {createStructuredSelector} from "reselect";
import {connect} from 'react-redux';
import {Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm, WrappedFieldProps} from 'redux-form';
import {numericality, required} from 'redux-form-validators';
import {Form, Grid, Message} from 'semantic-ui-react';

import {ReduxFormKeys} from '../../../form/ReduxFormKeys';
import { renderQuantity, renderRadio } from '../../../../shared/components/FormControls';
import {TabSection} from '../../../../shared/components/TabSection';
import {FormErrorList, syncedErrorsToList} from "../../../../shared/components/error-list";
import {InputForInteger} from "../../../../shared/controls/InputForInteger";
import {nl2br} from '../../../../localization/LocalizationService';
import { useLocalization } from '../../../../localization/hook';

import { BUILDING_TYPE, INSTALLATION_POSITION, LIFT_AREA_PERCENTAGE, MACHINE_ROOM, PROJECT_TYPE } from '../../../project/lookup';
import {isAllFormReady} from "../../calculation.selector";
import { FieldDisabledEntry, mutuallyExclusive as baseMutuallyExclusive, useFormUtility } from '../../FormUtility';
import {BASIC_INPUTS_FORM_VALUES_SELECTOR} from "../02-ConfigurationInputs/ConfigurationInputsForm";
import { getAvailableLiftAreaPercentages } from '../../CalculationService/LiftArea';
import AIOBasicSetsModal from './_AIOBasicSetsModal';


function addFlagsToCountries<T extends { key: string; }>(country: T) {
    // Semantic doesn't have flags for these
    const noFlagCountries = ['bl', 'mf', 'je', 'im', 'gg', 'cw', 'bq', 'aq', 'ss', 'sx'] as const;
    return {
        ...country,
        flag: noFlagCountries.includes(country.key as any) ? null : { name: country.key },
    }
}

function sortCountries<T extends { key: string; text: string; }>(a: T, b: T) {
    const preferredCountries = ['de', 'at'];
    const preferA = preferredCountries.findIndex(x => x === a.key);
    const preferB = preferredCountries.findIndex(x => x === b.key);

    if (preferA >= 0) {
        if (preferB >= 0) {
            return preferA - preferB;
        }
        return -1;
    }
    if (preferB >= 0) {
        return +1;
    }

    return a.text.localeCompare(b.text);
}

const renderError: React.FC<WrappedFieldProps> = ({ meta }) => (
    <div>{meta.error && meta.touched && 'Please select an option first'}</div>
);

function normInteger(value: string|null) {
    return value && parseInt(value);
}


const BasicInputsForm: React.FC<Props> = props => {
    const {
        syncedErrors,
        basicInputFormValues,
        isAllFormReady,
    } = props;

    const { localize, enumToLookup, countriesToLookup } = useLocalization();

    const fieldDisabledMappings: FieldDisabledEntry<typeof basicInputFormValues>[] = React.useMemo(() => {
        const mutuallyExclusive = baseMutuallyExclusive<typeof basicInputFormValues>();

        // Mapping of inputs' `disabled` prop
        return [
            ...mutuallyExclusive('nrwgDelivered', 'abzRequired'),
            ...mutuallyExclusive('additionalSmokeDetector', 'machineRoom', { bTruthy: machineRoom => !!machineRoom && machineRoom !== MACHINE_ROOM.NO }),
            ...mutuallyExclusive('calculatedVentilationArea', 'liftAreaPercentage', { aTruthy: () => false, bTruthy: p => p !== LIFT_AREA_PERCENTAGE.MANUAL }),
        ];
    }, []);

    const { fieldProps, fieldDisabled, FieldDisabledMessage } = useFormUtility(basicInputFormValues, fieldDisabledMappings);

    const countryOptions = React.useMemo(() =>
        countriesToLookup().map(addFlagsToCountries).sort(sortCountries),
        [countriesToLookup]
    );

    const liftAreaPercentageOptions = React.useMemo(() =>
        enumToLookup(getAvailableLiftAreaPercentages(basicInputFormValues ?? {}), 'LIFT_AREA_PERCENTAGE'),
        [basicInputFormValues, enumToLookup]
    );

    if (!isAllFormReady) return <></>;

    // Map error keys (field names) to localization key (prefix "field.")
    const errorList = syncedErrorsToList(syncedErrors).map(({ key, message }) => ({ key: 'field.' + key, message }));

    return (
        <>
            <Message
                info color='blue' icon='exclamation circle'
                content={nl2br(localize('info.energy_surcharge_Deutschland_GmbH'))}
            />
            <TabSection title={localize('basicInputs.section.info')}>
                <>
                    <Form>
                        <Form.Group widths="equal">
                            <Field {...fieldProps("projectName", { component: "input", required: true })} />
                            <Field {...fieldProps("buildingLocation", { component: "input", required: true })} />
                            <Field {...fieldProps("country", { component: "select-searchable", required: true })} options={countryOptions} />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Field {...fieldProps("projectType", { component: "select", required: true })} options={enumToLookup(Object.values(PROJECT_TYPE), 'PROJECT_TYPE')} />
                            <Field {...fieldProps("buildingType", { component: "select", required: true })} options={enumToLookup(Object.values(BUILDING_TYPE), 'BUILDING_TYPE')} />
                            <Field {...fieldProps("installationPosition", { component: "select", required: true })} options={enumToLookup(Object.values(INSTALLATION_POSITION), 'INSTALLATION_POSITION')} />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Field {...fieldProps("liftNumber", { component: "input" })} />
                            <Field {...fieldProps("plannedImplementationDate", { component: "input" })} />
                            <div style={{flex: '1 1 100%', padding: '0 .5em'}}></div>
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("nrwgDelivered", { component: "checkbox" })} infoText={basicInputFormValues.nrwgDelivered ? localize('info.nrwgRequirements') : ""} />
                            <FieldDisabledMessage name="nrwgDelivered" />
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("weathershelter", { component: "checkbox" })} />
                            <FieldDisabledMessage name="weathershelter" />
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("lowEnergyStandard", { component: "checkbox" })} infoText={localize('info.requiresBK-AIO')} />
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("abzRequired", { component: "checkbox" })} infoText={localize('info.requiresBK-AIO')} />
                            <FieldDisabledMessage name="abzRequired" />
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("quiet", { component: "checkbox" })} infoText={localize('info.requiresBK-AIO')} />
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("systemIntegration", { component: "checkbox" })} infoText={localize('info.BMA_consideration')} />
                        </Form.Group>

                        <Form.Group inline>
                            {/* basicInputFormValues.machineRoom === 'no' && (configurationInputFormValues.smokeDetection === 'LIFTBEAM' || configurationInputFormValues.smokeDetection === 'RAS') && // @TODO: Check this condition */}
                            <Field {...fieldProps("additionalSmokeDetector", { component: "checkbox" })} infoText={localize('info.additionalSmokeDetector')} />
                            <FieldDisabledMessage name="additionalSmokeDetector" />
                        </Form.Group>

                        <Form.Group inline>
                            <Field {...fieldProps("mainLevelSmokeDetector", { component: "checkbox" })} />
                        </Form.Group>

                        {
                            basicInputFormValues.projectType === PROJECT_TYPE.MODERNIZED &&
                            <p>{localize('info.existingHoleSize')}</p>
                        }
                    </Form>
                </>
            </TabSection>
            <TabSection title={localize('basicInputs.section.shaft')}>
                <>
                    <Form>
                        <div className="ui form">
                            <label>{localize('field.machineRoom')}*</label>
                            <Form.Group inline>
                                <Field component={renderRadio} label={localize("MACHINE_ROOM.up"  )} name="machineRoom" radioValue="up" disabled={fieldDisabled('machineRoom')}/>
                                <Field component={renderRadio} label={localize("MACHINE_ROOM.down")} name="machineRoom" radioValue="down" disabled={fieldDisabled('machineRoom')}/>
                                <Field component={renderRadio} label={localize("MACHINE_ROOM.no"  )} name="machineRoom" radioValue="no"/>
                                <FieldDisabledMessage name="machineRoom" />
                            </Form.Group>

                            <Field name="machineRoom" validate={[required()]} component={renderError} disabled={fieldDisabled('machineRoom')}/>

                            <Form.Group>
                                <Field {...fieldProps("noOfElevators", { required: true, validate: [numericality({ int: true, '<=': 2, msg: 'info.moreElevatorsPerShaft' })] })}
                                    min={1} max={2}
                                    infoText={basicInputFormValues.noOfElevators > 1 && localize('info.noOfElevators')}
                                    component={renderQuantity}
                                />
                            </Form.Group>
                            <p>{localize('info.moreElevatorsPerShaft')}</p>
                        </div>

                        <div className="flex-container flex-row form-group">
                            <div className="flex-50">
                                <Field {...fieldProps("shaftHeight", { required: true, validate: [numericality({int: true, '>': 0, '<': 120000, msg: 'validationShaftHeight'})] })}
                                    className="blue-theme"
                                    content="mm"
                                    component={InputForInteger}
                                    labelPosition="right"
                                />
                            </div>
                            <div className="flex-5"></div>
                            <div className="flex-50">
                                <Field {...fieldProps("shaftWidth", { required: true, validate: [numericality({int: true, '>': 0, '<': 20000, msg: 'validationShaftWidth'})] })}
                                    className="blue-theme"
                                    content="mm"
                                    component={InputForInteger}
                                    labelPosition="right"
                                />
                            </div>
                        </div>
                        <div className="flex-container flex-row form-group">
                            <div className="flex-50">
                                <Field {...fieldProps("shaftDepth", { required: true, validate: [numericality({int: true, '>': 0, '<': 10000, msg: 'validationShaftDepth'})] })}
                                    className="blue-theme"
                                    content="mm"
                                    component={InputForInteger}
                                    labelPosition="right"
                                />
                            </div>
                            <div className="flex-5"></div>
                            <div className="flex-50">
                                <Field {...fieldProps("payload")}
                                    className="blue-theme"
                                    content="kg"
                                    component={InputForInteger}
                                    labelPosition="right"
                                />
                            </div>
                        </div>
                    </Form>
                </>
                <AIOBasicSetsModal/>
                {/*
                <Field
                    component={renderHidden}
                    name="aioBasicSet"
                />
                */}
            </TabSection>

            <Grid id="calculateVentilationArea" columns={2} textAlign="center">
                <Grid.Row>
                    <Grid.Column width={9} textAlign="right"></Grid.Column>
                    <Grid.Column width={5} textAlign="left">
                        <h5>{localize('field.calculatedVentilationArea')}</h5>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={9} textAlign="right">
                        <Form.Group>
                            {liftAreaPercentageOptions.map(liftAreaPercentage =>
                                <Field
                                    component={renderRadio}
                                    name="liftAreaPercentage"
                                    label={liftAreaPercentage.text}
                                    radioValue={liftAreaPercentage.value}
                                    key={liftAreaPercentage.value}
                                />
                            )}
                            <Field name="liftAreaPercentage" validate={[required()]} component={renderError}/>
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column width={5} textAlign="left">
                        <Form.Group>
                            <Field fluid
                                {...fieldProps("calculatedVentilationArea", { component: 'input', required: basicInputFormValues.liftAreaPercentage === LIFT_AREA_PERCENTAGE.MANUAL })}
                                normalize={normInteger}
                            />
                            {[LIFT_AREA_PERCENTAGE._2_5_PERCENT, LIFT_AREA_PERCENTAGE._1_0_PERCENT].includes(basicInputFormValues.liftAreaPercentage as any) && <>
                                <div className="ui divider"></div>
                                <label>{localize('info.ventilation_area_minimum_lbo')}</label>
                            </>}
                        </Form.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <FormErrorList errorList={errorList}/>
        </>
    );
};


const mapStateToProps = createStructuredSelector({
    isAllFormReady,
    syncedErrors: getFormSyncErrors(ReduxFormKeys.basicInputsForm),
    basicInputFormValues: getFormValues(ReduxFormKeys.basicInputsForm) as BASIC_INPUTS_FORM_VALUES_SELECTOR,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: ReduxFormKeys.basicInputsForm,
        touchOnChange: true,
        touchOnBlur: true,
        keepDirtyOnReinitialize: true,
        enableReinitialize: true,
    })(BasicInputsForm)
)

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps & InjectedFormProps;
